<style lang="scss" scoped>
.tips {
  font-size: 16px;
  font-family: PingFang SC-常规体, PingFang SC;
  font-weight: normal;
  color: #7f7f7f;
  line-height: 24px;
  margin-top: 15px;
}

.list-li {
  background: #f8f8f8;
  border-radius: 8px;
  margin-top: 20px;
  padding: 20px;

  .user {
    font-size: 18px;
    font-family: PingFang SC-中粗体, PingFang SC;
    font-weight: normal;
    color: #1a1a1a;
    line-height: 18px;
    display: flex;

    .name {
      margin-right: 20px;
    }
  }

  .user_address {
    font-size: 16px;
    font-family: PingFang SC-常规体, PingFang SC;
    font-weight: normal;
    color: #7f7f7f;
    line-height: 24px;
    margin-top: 15px;
  }

  .btn-box {
    display: flex;
    justify-content: right;
    align-items: center;
    position: relative;
    height: 30px;
    margin-top: 10px;

    .bt {
      width: 66px;
      height: 30px;
      border-radius: 4px;
      opacity: 1;
      border: 1px solid #b1b1b1;
      font-size: 14px;
      font-family: PingFang SC-常规体, PingFang SC;
      font-weight: normal;
      text-align: center;
      color: #7f7f7f;
      line-height: 30px;
      margin-left: 10px;
    }

    .checkbox {
      position: absolute;
      left: 0;

      ::v-deep .el-checkbox__input {
        border-radius: 50%;
        overflow: hidden;

        .el-checkbox__inner {
          border-radius: 50%;
        }
      }
    }
  }
}

.noorder {
  padding-top: 122px;

  .noimg {
    margin: 0 auto;
    width: 130px;
    height: 130px;
    background: url("https://p6.moimg.net/mdwxapp/empty_order_icon.png") center/cover;
  }

  p {
    text-align: center;
    margin-top: 10px;
    height: 12px;
    font-size: 12px;
    font-family: PingFang SC-常规体, PingFang SC;
    font-weight: normal;
    color: #9e9e9e;
    line-height: 12px;
  }
}

.tispbtn {
  // width: 119px;
  // height: 28px;
  // background: #ebfbf8;
  // border-radius: 4px 4px 4px 4px;
  // font-size: 14px;
  // font-family: PingFang SC-常规体, PingFang SC;
  // font-weight: normal;
  // color: #00c4a1;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  // transform: translateY(-50%);
  // .icon-plus {
  //   margin-right: 5px;
  // }
}
</style>
<template>
  <div>
    <CompHeader title="管理地址簿">
      <template v-if="state.list.length < 10" #btn>
        <el-button class="tispbtn" type="primary" @click="toDetails" size="small" text bg>
          <el-icon class="icon-plus">
            <Upload />
          </el-icon>
          添加收货地址
        </el-button>
        <!-- <div class="tispbtn" @click="toDetails">
          <span class="icon-plus"></span>添加收货地址
        </div> -->
      </template>
    </CompHeader>
    <div class="tips">
      您已经设置了{{ state.list.length }}个收货地址，最多可以创建10个
    </div>
    <div class="list-ul" v-if="state.list.length">
      <div class="list-li" v-for="item in state.list" :key="item.id">
        <div class="user">
          <div class="name">{{ item.address_name }}</div>
          <div class="tel">{{ item.address_mobile }}</div>
        </div>

        <div class="user_address">
          {{ item.province }} {{ item.city }} {{ item.county }}
          {{ item.street }}
          {{ item.address }}
        </div>
        <div class="btn-box">
          <el-checkbox v-show="item.is_default" class="checkbox" v-model="item.is_default"
            :label="item.is_default ? '默认地址' : '设为默认地址'" :true-label="true" @change="defaultFn(item)" text-color="#00C4A1"
            fill="#00C4A1" size="large" />
          <el-button type="" @click="toDetails(item)" size="small">编辑</el-button>
          <!-- <div class="bt" @click="toDetails(item)">编辑</div> -->
          <el-button type="" @click="del(item)" size="small">删除</el-button>
          <!-- <div class="bt" @click="del(item)">删除</div> -->
        </div>
      </div>
    </div>
    <div class="noorder" v-else>
      <div class="content">
        <div class="noimg"></div>
        <p>暂无地址</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  // eslint-disable-next-line no-unused-vars
  ref,
  // eslint-disable-next-line no-unused-vars
  watch,
  onMounted,
  reactive,
  // eslint-disable-next-line no-unused-vars
  defineExpose,
  // eslint-disable-next-line no-unused-vars
  computed,
  onBeforeUnmount,
} from "vue";
// eslint-disable-next-line no-unused-vars
import { get, post } from "@/utils/apiBase.js";
import CompHeader from "@@/common/header.vue";
// eslint-disable-next-line no-unused-vars
import { ElMessage, ElMessageBox } from "element-plus";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
const store = useStore();
const router = useRouter();

onMounted(() => {
  getList();
});
onBeforeUnmount(() => { });
const state = reactive({
  loading: true,
  list: [],
  user_id: store.state.user.userId,
  page: 1,
  postCount: 0,
  nav: 100,
});
const toDetails = (item) => {
  if (item && item.id) {
    router.push(`/address/add_address/${item.id}?item=${JSON.stringify(item)}`);
  } else {
    router.push(`/address/add_address`);
  }
};
// eslint-disable-next-line no-unused-vars
const defaultFn = async (item) => {
  item.is_default = true
  let form = {};
  form.address_id = item.id;
  form.pro_posi = item.province;
  form.province_id = item.province_id;
  form.city = item.city;
  form.city_id = item.city_id;
  form.county = item.county;
  form.area_id = item.area_id;
  form.detail = item.address;
  form.country_code = item.country_code;
  form.mobile = Number(item.address_mobile);
  form.address_mobile = item.address_mobile;
  form.name = item.address_name;
  form.address_name = item.address_name;
  form.postal_code = Number(item.address_postal_code)
    ? Number(item.address_postal_code)
    : "";
  form.street = item.street;
  form.is_default = item.is_default ? 1 : 0;
  console.log(item);
};
const getList = async () => {
  try {
    const { data } = await get("/apis/address/get_address_list", {
      json_type: "json",
      user_id: state.user_id,
    });
    state.list = data;
  } catch (e) {
    console.log(e);
  }
};

const del = async (item) => {
  ElMessageBox.confirm("是否确认删除该地址?", "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
  })
    .then(() => {
      deleteItem(item);
    })
    .catch(() => { });
};
const deleteItem = async (item) => {
  post("/apis/address/delete_address", {
    address_id: item.id,
    user_id: state.user_id,
    json_type: "json",
  }).then(() => {
    ElMessage({
      showClose: true,
      message: "删除成功",
      type: "success",
    });
    getList();
  });
};
// const reloadScroll = () => {
//   const localTop = document.documentElement.scrollTop || 0;
//   const domHeight = document.documentElement.scrollHeight || 0;
//   const domClientHeight = document.documentElement.clientHeight || 0;

//   if (state.loading) {
//     return false;
//   }
//   if (localTop && domHeight < localTop + domClientHeight + 50) {
//     state.loading = true;
//     state.page++;
//     getList();
//   }
// };
// eslint-disable-next-line no-unused-vars
const lowerdev = {
  addbind(element, type, handler) {
    if (element.addEventListener) {
      element.addEventListener(type, handler, false);
    } else if (element.attachEvent) {
      element.attachEvent("on" + type, handler);
    } else {
      element["on" + type] = handler;
    }
  },
  removebind(element, type, handler) {
    if (element.removeEventListener) {
      element.removeEventListener(type, handler, false);
    } else if (element.detachEvent) {
      element.detachEvent("on" + type, handler);
    } else {
      element["on" + type] = null;
    }
  },
};
</script>
